import { ModalType } from "../types";

let showModalFn: ((type: ModalType) => void) | undefined;
let hideModalFn: (() => void) | undefined;

export const modalAPI = {
  initialize: (showModal: (type: ModalType) => void, hideModal: () => void) => {
    showModalFn = showModal;
    hideModalFn = hideModal;
  },
  show: (type: ModalType) => {
    if (!showModalFn) {
      console.warn("Modal API not initialized");
      return;
    }
    showModalFn(type);
  },
  hide: () => {
    if (!hideModalFn) {
      console.warn("Modal API not initialized");
      return;
    }
    hideModalFn();
  },
};
