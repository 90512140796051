import React from "react";
import cn from "classnames";

import styles from "./PackageList.module.css";
import { PackageItem } from "./components/PackageItem";

interface Item {
  id: string;
  title?: string;
}

interface PackageListProps {
  items: Item[];
  onItemClick: (id: string) => void;
  className?: string;
  selected?: string;
}

export const PackageList: React.FC<PackageListProps> = ({
  onItemClick,
  className,
  items,
  selected,
}) => (
  <div className={cn(styles.root, className)}>
    {items.map((item, index) => (
      <PackageItem
        id={item.id}
        className={cn({
          [styles.rotateRight]: index % 2 !== 0,
          [styles.rotateLeft]: index % 2 === 0,
        })}
        selected={item.id === selected}
        title={item.title}
        color={((index % 4) + 1) as 1 | 2 | 3 | 4}
        key={item.id}
        onClick={onItemClick}
      />
    ))}
  </div>
);
