import React from "react";
import cn from "classnames";
import styles from "./Slider.module.css";

type SliderProps = {
  amount: number;
  current: number;
  color?: 1 | 2 | 3 | 4;
};

const DOT_WIDTH = 8;
const CURRENT_DOT_WIDTH = 26;
const DOT_SPACING = 4;

interface DotProps {
  index: number;
  isCurrent: boolean;
  xOffset: number;
}

const Dot: React.FC<DotProps> = ({ index, isCurrent, xOffset }) => {
  const width = isCurrent ? CURRENT_DOT_WIDTH : DOT_WIDTH;

  return (
    <rect
      key={index}
      width={width}
      height="8"
      rx="4"
      fill="#C6873F"
      opacity={isCurrent ? "1" : "0.3"}
      x={xOffset}
    />
  );
};

interface DotsProps {
  amount: number;
  current: number;
}

const createDots = ({ amount, current }: DotsProps) => {
  let xOffset = 0;
  const dots = Array.from({ length: amount }).map((_, index) => {
    const isCurrent = index === current;
    const dot = (
      <Dot key={index} index={index} isCurrent={isCurrent} xOffset={xOffset} />
    );
    xOffset += (isCurrent ? CURRENT_DOT_WIDTH : DOT_WIDTH) + DOT_SPACING;
    return dot;
  });

  return {
    dots,
    totalWidth: xOffset - DOT_SPACING, // Subtract last spacing
  };
};

export const Slider: React.FC<SliderProps> = ({ amount, current, color }) => {
  const { dots, totalWidth } = createDots({ amount, current });

  return (
    <svg
      className={cn(styles.root, styles[`color-${color || 2}`])}
      width={totalWidth}
      height="8"
      viewBox={`0 0 ${totalWidth} 8`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {dots}
    </svg>
  );
};
