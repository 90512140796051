import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Main.module.css";
import logo from "../../assets/logo.png";
import { useNavigationGuard } from "../../features/navigation";
import { authenticationAPI } from "../../features/authentication/api";

export const Main: React.FC = () => {
  const navigationGuard = useNavigationGuard();
  const navigate = useNavigate();

  const handleMyLibraryClick = useCallback(() => {
    navigationGuard.to("stories");
  }, [navigationGuard]);

  const handleCreateStoryClick = useCallback(() => {
    navigationGuard.to("create");
  }, [navigationGuard]);

  const handleLogoutClick = useCallback(async () => {
    await authenticationAPI.signInAnonymously();
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.root}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="random" />
      </div>
      <div className={styles.thumbnailsContainer}>
        <ButtonThumbnail onClick={handleMyLibraryClick} value="My Library" />
        <ButtonThumbnail
          onClick={handleCreateStoryClick}
          value="Create Story"
        />
        <ButtonThumbnail onClick={handleLogoutClick} value="Log Out" />
        <ButtonThumbnail onClick={() => {}} value="My Characters" />
      </div>
    </div>
  );
};

type ButtonThumbnailProps = {
  value: string;
  onClick: () => void;
};

const ButtonThumbnail: React.FC<ButtonThumbnailProps> = ({
  value,
  onClick,
}) => (
  <div onClick={onClick} className={styles.thumbnail}>
    {value}
  </div>
);
