import { StoryDetails } from "../../../App/types";
import { PageProps } from "../Components/Page";

export type PageType = "content" | "end";

export type StoryPage = Omit<PageProps, "pageAmount" | "pageIndex"> & {
  type: PageType;
};

export const getStoryImages = (
  storyDetails?: StoryDetails | null,
): (string | undefined)[] =>
  storyDetails?.content?.sections.map((section) => section.imageUrl) || [];

export const getStorySpeechUrls = (
  storyDetails?: StoryDetails | null,
): (string | undefined)[] =>
  storyDetails?.content?.sections.map((section) => section.speechUrl) || [];

export const hasSpeechContent = (speechUrls: (string | undefined)[]): boolean =>
  speechUrls.filter((v) => !!v).length > 0;

export const storyDetailsToPages = (
  story?: StoryDetails | null,
): StoryPage[] => {
  if (!story?.content) {
    return [];
  }

  const title = story.name;
  const contentPages = story.content.sections.map((section, index) => ({
    type: "content" as const,
    text: section.text,
    imageUrl: section.imageUrl,
    speechUrl: section.speechUrl,
    ...(index === 0 && { title }),
  }));

  // Add end page after all content pages
  const endPage: StoryPage = {
    type: "end" as const,
    text: "The End",
    color: contentPages.length % 2 === 0 ? 2 : 3, // Alternate color based on last content page
  };

  return [...contentPages, endPage];
};
