import React from "react";
import cn from "classnames";
import styles from "./CardCarousel.module.css";
import { Card } from "./components/Card/Card";
import { CardAdd } from "./components/CardAdd";

type CardItem = {
  id: string;
  title: string;
  thumbnail: string;
};

interface CardCarouselProps {
  items: CardItem[];
  selected?: string[];
  onClick?: (item: { type: "item"; id: string } | { type: "add" }) => void;
  className?: string;
  addItem?: boolean;
}

export const CardCarousel: React.FC<CardCarouselProps> = ({
  className,
  items,
  selected,
  onClick,
  addItem,
}) => {
  const [shuffledItems, setShuffledItems] = React.useState<CardItem[]>([]);

  React.useEffect(() => {
    // If no items exist yet, don't do anything
    if (items.length === 0) {
      setShuffledItems([]);
      return;
    }

    // Get current item IDs for comparison
    const currentIds = new Set(shuffledItems.map((item) => item.id));
    const newIds = new Set(items.map((item) => item.id));

    // Only update if there are actual changes
    const hasRemovedItems = shuffledItems.some((item) => !newIds.has(item.id));
    const hasNewItems = items.some((item) => !currentIds.has(item.id));

    if (hasRemovedItems || hasNewItems || shuffledItems.length === 0) {
      // Keep existing order for current items and shuffle only new ones
      const existingItems = shuffledItems.filter((item) => newIds.has(item.id));
      const newItems = items
        .filter((item) => !currentIds.has(item.id))
        .sort(() => Math.random() - 0.5);

      setShuffledItems([...existingItems, ...newItems]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]); // Only depend on items prop

  return (
    <div className={cn(styles.root, className)}>
      {addItem && (
        <CardAdd
          id="add"
          onClick={() => onClick && onClick({ type: "add" })}
          className={styles.add}
        />
      )}
      {shuffledItems.map((item, index) => (
        <Card
          key={item.id}
          id={item.id}
          color={((index % 4) + 1) as 1 | 2 | 3 | 4}
          title={item.title}
          thumbnail={item.thumbnail}
          selected={selected && selected.includes(item.id)}
          onClick={() => onClick && onClick({ type: "item", id: item.id })}
          className={cn({
            [styles.rotateRight]: index % 2 === 0,
            [styles.rotateLeft]: index % 2 !== 0,
          })}
        />
      ))}
    </div>
  );
};
