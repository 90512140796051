import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useMemo } from "react";
import styles from "./Authentication.module.css";
import { supabaseAPI } from "../../supabase";
import { useAuth } from "../../hooks/useAuth";

const isSignup = (isAnonymous: boolean, isAuthenticated: boolean) => {
  return !isAuthenticated || isAnonymous;
};

export function Authentication() {
  const { isAuthenticated, isAnonymous } = useAuth();
  const view = useMemo(
    () => (isSignup(isAnonymous, isAuthenticated) ? "sign_up" : "sign_in"),
    [isAnonymous, isAuthenticated],
  );

  return (
    <div className={styles.root}>
      <Auth
        supabaseClient={supabaseAPI.getSupabaseClient()}
        appearance={{
          theme: ThemeSupa,
          className: {
            anchor: styles.anchor,
            message: styles.message,
            container: styles.container,
            label: styles.label,
            button: styles.button,
            input: styles.input,
            loader: styles.loader,
            divider: styles.divider,
          },
        }}
        view={view}
        socialLayout="horizontal"
        providers={["google", "facebook", "twitter"]}
        redirectTo="https://www.storyaday.co/"
        theme=""
      />
    </div>
  );
}
