import React, { useEffect } from "react";
import _ from "lodash";
import cn from "classnames";

import { ReactComponent as Book } from "@assets/book.svg";
import styles from "./ListItemWriting.module.css";
import { ProgressBar } from "./components/ProgressBar";

export interface ListItemWritingProps {
  className?: string;
  created_at?: string;
}

const STORY_CREATION_TIME = 120;

export const ListItemWriting: React.FC<ListItemWritingProps> = ({
  className,
  created_at,
}) => {
  const [progress, setProgress] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const completionTime = created_at && getRemainingTimeInSeconds(created_at);

  useEffect(() => {
    if (_.isNumber(completionTime)) {
      setProgress(
        completionTime === 0
          ? 100
          : ((STORY_CREATION_TIME - completionTime) / STORY_CREATION_TIME) *
              100,
      );
    } else {
      setProgress(100);
    }

    setTimeout(() => {
      setReady(true);
    }, 1000);
  }, [completionTime]);

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.innerContainer}>
        <div className={styles.topContainer}>
          <div className={styles.imageContainer}>
            <Book className={styles.image} />
          </div>
          <div className={styles.title}>Imagination At Work</div>
        </div>
        <div className={styles.bottomContainer}>
          <ProgressBar
            progress={progress}
            ready={ready}
            completionTime={completionTime || 0}
          />
        </div>
      </div>
    </div>
  );
};

function getRemainingTimeInSeconds(timeString: string): number | null {
  const currentTime = new Date();
  const givenTime = new Date(timeString);

  // Check if the given time string is a valid date
  if (Number.isNaN(givenTime.getTime())) {
    console.error("Invalid time string format");
    return null;
  }

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = currentTime.getTime() - givenTime.getTime();

  // Convert milliseconds to seconds
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  const remainingInSeconds = STORY_CREATION_TIME - differenceInSeconds;
  return remainingInSeconds > 0 ? remainingInSeconds : 0;
}
