// src/Book.tsx
import React, { useMemo } from "react";
import { useSprings } from "@react-spring/web";
import { useParams } from "react-router-dom";

import styles from "./Story.module.css";
import { Header } from "../../components";
import { FooterAudioPlayer } from "../../features/story/components/FooterAudioPlayer";
import { useStory } from "../../features/story/hooks/stories";
import { usePageNavigation } from "./hooks/usePageNavigation";
import { usePageDrag } from "./utils/usePageDrag";
import { AnimatedPage } from "./Components/AnimatedPage";
import {
  getStoryImages,
  getStorySpeechUrls,
  hasSpeechContent,
  storyDetailsToPages,
} from "./utils/storyUtils";
import useImagePreloader from "./utils/imagePreloader";

export const Story: React.FC = () => {
  const { slug } = useParams();
  const story = useStory(slug);

  const pages = useMemo(() => storyDetailsToPages(story), [story]);
  const urls = useMemo(() => getStoryImages(story), [story]);
  const speechUrls = useMemo(() => getStorySpeechUrls(story), [story]);
  const hasSpeech = useMemo(() => hasSpeechContent(speechUrls), [speechUrls]);

  const { currentPage, isLastPage, goToNextPage, goToPrevPage } =
    usePageNavigation(pages.length);

  useImagePreloader(urls.filter((v): v is string => !!v));

  const [springs, api] = useSprings(pages.length, (i) => ({
    from: {
      x: 0,
      y: 0,
      scale: 1,
      rot: 0,
      zIndex: pages.length - i,
    },
    zIndex: pages.length - i,
    x: 0,
    y: 0,
    scale: 1,
    rot: 0,
  }));

  const bind = usePageDrag(
    currentPage,
    isLastPage,
    goToNextPage,
    goToPrevPage,
    api,
  );

  return (
    <div className={styles.root}>
      <Header fixedPosition={false} withBackButton withBackground={false} />
      <div {...bind()} className={styles.pagesContainer}>
        {pages.map((page, index) => (
          <AnimatedPage
            key={index}
            spring={springs[index]}
            pageIndex={index}
            pageAmount={pages.length}
            {...page}
          />
        ))}
      </div>
      {hasSpeech && (
        <FooterAudioPlayer
          audioUrls={speechUrls}
          fixedBottom={false}
          currentIndex={currentPage}
        />
      )}
    </div>
  );
};
