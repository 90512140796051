import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import splashImage from "@assets/the-end.jpg";
import { ReactComponent as Book } from "@assets/book.svg";
import { ReactComponent as Add } from "@assets/plus-circle.svg";
import { useNavigationGuard } from "features/navigation";
import { MenuList } from "components/shared/MenuList";
import styles from "./EndPage.module.css";
import { Slider } from "../../../../features/story/components/Slider";

export type PageProps = {
  color?: 2 | 3;
  pageIndex: number;
  pageAmount: number;
  className?: string;
};

export const Page: React.FC<PageProps> = ({
  className,
  color,
  pageAmount,
  pageIndex,
}) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const [hasOverflow, setHasOverflow] = useState(false);
  const navigationGuard = useNavigationGuard();

  const menuItems = [
    {
      id: "stories",
      title: "My Stories",
      icon: <Book />,
      showArrow: true,
      onClick: () => navigationGuard.to("stories"),
    },
    {
      id: "create",
      title: "Create New Story",
      icon: <Add />,
      showArrow: true,
      onClick: () => navigationGuard.to("create"),
    },
  ];

  useEffect(() => {
    const container = rootRef.current;
    const slider = sliderRef.current;
    if (container) {
      const hasOverflowX = container.scrollWidth > container.clientWidth;
      const hasOverflowY =
        container.scrollHeight >
        container.clientHeight - (slider?.clientHeight || 0);
      setHasOverflow(hasOverflowX || hasOverflowY);
    }
  }, []);

  return (
    <div
      ref={rootRef}
      className={cn(
        styles.root,
        styles[`color-${color || 2}`],
        { [styles.overflow]: hasOverflow },
        className,
      )}
    >
      <div className={styles.containerWrapper}>
        <div className={styles.imageContainer}>
          <img className={styles.image} alt="page" src={splashImage} />
        </div>
        <div
          className={cn(
            styles.imageContainerBackground,
            styles[`imageContainerBackgroundColor${color || 2}`],
          )}
        />
        <div className={styles.imageContainerBackgroundBlur} />
      </div>
      <div className={styles.buttonsContainer}>
        <MenuList items={menuItems} />
      </div>
      {/* <div className={styles.sliderContainer} ref={sliderRef}>
        <Slider color={color} amount={pageAmount} current={pageIndex} />
      </div> */}
    </div>
  );
};
