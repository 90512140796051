import { useEffect, useRef } from "react";
import { assetRegistry } from "../config/assetConfig";

export const AssetPreloader = () => {
  const preloadedAssets = useRef<Set<string>>(new Set());

  useEffect(() => {
    const assets = preloadedAssets.current;

    const injectPreloadTag = (
      path: string,
      type: string,
      format?: string,
      crossOrigin?: boolean,
    ) => {
      if (assets.has(path)) return;

      const link = document.createElement("link");
      link.rel = "preload";
      link.href = `${process.env.PUBLIC_URL}/${path}`;
      link.as = type;
      if (format) link.type = format;
      if (crossOrigin) link.crossOrigin = "anonymous";

      document.head.appendChild(link);
      assets.add(path);
    };

    const cleanup: string[] = [];

    // Process all assets
    assetRegistry.getAllAssets().forEach((asset) => {
      if (!assets.has(asset.path)) {
        injectPreloadTag(
          asset.path,
          asset.type,
          asset.format,
          asset.crossOrigin,
        );
        cleanup.push(asset.path);
      }
    });

    // Cleanup function to remove preload tags when component unmounts
    return () => {
      cleanup.forEach((path) => {
        const link = document.querySelector(`link[href*="${path}"]`);
        if (link) {
          document.head.removeChild(link);
          assets.delete(path);
        }
      });
    };
  }, []); // Empty dependency array since we want this to run once on mount

  return null; // This component doesn't render anything
};
