interface SplashScreenState {
  hasSeenSplash: boolean;
  intendedPath?: string;
}

const STORAGE_KEY = "splashScreen";

const getState = (): SplashScreenState => {
  const stored = sessionStorage.getItem(STORAGE_KEY);
  return stored ? JSON.parse(stored) : { hasSeenSplash: false };
};

const setState = (newState: Partial<SplashScreenState>): void => {
  const currentState = getState();
  sessionStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      ...currentState,
      ...newState,
    }),
  );
};

const hasSeenSplash = (): boolean => {
  const state = getState();
  return state.hasSeenSplash;
};

const setIntendedPath = (path: string): void => {
  setState({ intendedPath: path });
};

const getIntendedPath = (): string | undefined => {
  const state = getState();
  return state.intendedPath;
};

const clearIntendedPath = (): void => {
  setState({ intendedPath: undefined });
};

const markAsSeen = (): void => {
  setState({ hasSeenSplash: true });
};

export const splashScreenAPI = {
  hasSeenSplash,
  setIntendedPath,
  getIntendedPath,
  clearIntendedPath,
  markAsSeen,
};
