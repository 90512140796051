import React from "react";
import { animated, to as interpolate, SpringValue } from "@react-spring/web";
import { Page, PageProps } from "../Page";
import { Page as EndPage } from "../EndPage2/EndPage";
// import { EndPage } from "../EndPage";
import { PageType } from "../../utils/storyUtils";
import styles from "./AnimatedPage.module.css";

interface AnimatedPageProps extends PageProps {
  spring: {
    zIndex: SpringValue<number>;
    x: SpringValue<number>;
    y: SpringValue<number>;
    rot: SpringValue<number>;
    scale: SpringValue<number>;
  };
  pageIndex: number;
  pageAmount: number;
  type: PageType;
}

const trans = (r: number, s: number) => `rotateZ(${r * 20}deg) scale(${s})`;

export const AnimatedPage: React.FC<AnimatedPageProps> = ({
  spring,
  pageIndex,
  pageAmount,
  type,
  ...pageProps
}) => {
  return (
    <animated.div
      style={{
        zIndex: spring.zIndex,
        x: spring.x,
        y: spring.y,
        transform: interpolate([spring.rot, spring.scale], trans),
      }}
      className={styles.pageContainer}
    >
      {type === "end" ? (
        <EndPage
          {...pageProps}
          pageIndex={pageIndex}
          pageAmount={pageAmount}
          color={pageIndex % 2 === 0 ? 2 : 3}
          className={styles.page}
        />
      ) : (
        <Page
          {...pageProps}
          pageIndex={pageIndex}
          pageAmount={pageAmount}
          color={pageIndex % 2 === 0 ? 2 : 3}
          className={styles.page}
        />
      )}
    </animated.div>
  );
};
