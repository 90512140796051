import { StoryCreationRequest } from "features/story/types";
import { Seed } from "../../../App/types";

export const delay = (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

type CreateStoryStatus =
  | "loading"
  | "anonymous-credit"
  | "authenticated-credit"
  | "anonymous-no-credit"
  | "authenticated-no-credit";

export const getStatus = (
  loadingBalance: boolean,
  isAnonymous: boolean,
  hasBalanceRemaining: boolean,
): CreateStoryStatus => {
  if (loadingBalance) {
    return "loading";
  }

  if (isAnonymous && hasBalanceRemaining) {
    return "anonymous-credit";
  }

  if (isAnonymous && !hasBalanceRemaining) {
    return "anonymous-no-credit";
  }

  if (!isAnonymous && hasBalanceRemaining) {
    return "authenticated-credit";
  }

  if (!isAnonymous && !hasBalanceRemaining) {
    return "authenticated-no-credit";
  }

  return "loading";
};

export const seedToCardItem = (seed: Seed) => ({
  id: seed.id,
  title: seed.title,
  thumbnail: seed.url,
  type: seed.type,
});

export const createStoryRequest = (
  inputTextAbout: string,
  inputTextCharacters: string,
  selectedSeeds: Seed[],
): StoryCreationRequest => {
  return {
    about: inputTextAbout,
    characters: {
      manual: inputTextCharacters,
      seeds: selectedSeeds
        .filter((seed) => seed.type === "character")
        .map((seed) => ({
          id: seed.id,
          prompt: seed.prompt,
          type: "character",
        })),
    },
    activities: {
      seeds: selectedSeeds
        .filter((seed) => seed.type === "activity")
        .map((seed) => ({
          id: seed.id,
          prompt: seed.prompt,
          type: "activity",
        })),
    },
  };
};
