export const RouteIds = {
  splash: "splash",
  stories: "stories",
  login: "login",
  signup: "signup",
  main: "main",
  payment: "payment",
  paymentCompletion: "paymentCompletion",
  package: "package",
  story: "story",
  create: "create",
  profile: "profile",
  privacyPolicy: "privacyPolicy",
} as const;

export type RouteId = keyof typeof RouteIds;
