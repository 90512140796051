import React from "react";
import cn from "classnames";
import { ReactComponent as More } from "@assets/three-vertical-dots.svg";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
import { Back } from "../Back";

interface HeaderProps {
  className?: string;
  withBackButton?: boolean;
  withMenu?: boolean;
  withBackground?: boolean;
  fixedPosition?: boolean;
  title?: string;
}

export const Header: React.FC<HeaderProps> = ({
  className,
  withBackButton,
  withMenu,
  fixedPosition,
  withBackground,
  title,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={cn(
        styles.root,
        {
          [styles.fixed]: fixedPosition,
          [styles.withBackground]: withBackground,
        },
        className,
      )}
    >
      {withBackButton && <Back />}
      {title && <div className={styles.title}>{title}</div>}
      {withMenu && (
        <HamburgerMenu
          onClick={() => {
            navigate("/profile");
          }}
        />
      )}
    </div>
  );
};

const HamburgerMenu: React.FC<{ src?: string; onClick?: () => void }> = ({
  src,
  onClick,
}) => (
  <div className={styles.blob} onClick={onClick}>
    {src ? <img src={src} alt="profile" /> : <More />}
  </div>
);
