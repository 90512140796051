import React, { useMemo } from "react";
// import profileImage from '@assets/profile-image.webp';
// import { ProfileThumbnail } from '../../components/ProfileThumbnail';
import logo from "@assets/logo.png";

import { Crown, Shield, LogOut, Mail } from "lucide-react";
import { MenuList } from "../../components/shared/MenuList";
import { Header } from "../../components";
import styles from "./Profile.module.css";
import { useAuth } from "../../features/authentication";
import { useNavigationGuard } from "../../features/navigation";

export const Profile = () => {
  const { session, signOut } = useAuth();
  const navigationGuard = useNavigationGuard();
  const profileItems = useMemo(
    () => [
      //     {
      //     id: 'edit-profile',
      //     title: 'Edit Profile',
      //     icon: <Edit />,
      //     showArrow: true,
      // },
      {
        id: "package",
        title: "Package",
        icon: <Crown />,
        showArrow: true,
        onClick: () => navigationGuard.to("package"),
      },
      {
        id: "privacy-policy",
        title: "Privacy Policy",
        icon: <Shield />,
        showArrow: true,
        onClick: () => navigationGuard.to("privacyPolicy", {}, false),
      },
      {
        id: "constuct-us",
        title: "Contact Us",
        icon: <Mail />,
        showArrow: true,
        onClick: () => {
          window.open("mailto:info@storyaday.ai", "_blank");
        },
      },
      {
        id: "logout",
        title: "Logout",
        icon: <LogOut />,
        onClick: signOut,
      },
    ],
    [signOut, navigationGuard],
  );

  return (
    <div className={styles.root}>
      <Header withBackButton fixedPosition withBackground />
      <div className={styles.picture}>
        <img className={styles.logo} src={logo} alt="random" />
        {/* <ProfileThumbnail imageUrl={profileImage} /> */}
      </div>
      <div className={styles.content}>
        {/* <div className={styles.name}>
                    Tomer Marx
                </div> */}
        {session && <div className={styles.email}>{session.user.email}</div>}
      </div>

      <div className={styles.buttonsContainer}>
        <MenuList items={profileItems} />
      </div>
    </div>
  );
};
