import React from "react";
import cn from "classnames";
import styles from "./Thumbnail.module.css";

export type ThumbnailProps = {
  className?: string;
  icon?: JSX.Element;
  color?: 1 | 2 | 3 | 4;
};

export const Thumbnail: React.FC<ThumbnailProps> = ({
  icon,
  className,
  color = 1,
}) => (
  <div
    className={cn(styles.thumbnail, styles[`root-color-${color}`], className)}
  >
    {icon}
  </div>
);
