import React, { useState } from "react";
import { ReactComponent as Crown } from "@assets/crown.svg";
import { useParams } from "react-router-dom";
import { useProduct } from "features/premium/hooks/products";
import styles from "./Payment.module.css";
import { PaymentButtons } from "../../features/payment/deprecated";
import { useAuth } from "../../features/authentication";
import { useModal } from "../../features/modal/hooks/modals";
import { Header } from "../../components";
import { Checkout } from "../../features/payment";

export const Payment = () => {
  const { session } = useAuth();
  const { showModal } = useModal();
  const { slug: productId } = useParams();
  const product = useProduct(productId);

  const [message, setMessage] = useState<string | undefined>(undefined);

  const handlePaymentComplete = async () => {
    setMessage("Payment Successfull");
    showModal("paymentSuccess");
  };

  const handleFailure = (_message: string) => {
    setMessage(_message);
    showModal("paymentError");
  };

  if (!session) {
    return <div>Not logged in</div>;
  }

  if (productId === undefined) {
    return <div>No Product Defined</div>;
  }

  if (product === undefined) {
    return <div />;
  }

  return (
    <div className={styles.root}>
      <Header fixedPosition withBackButton />
      <div className={styles.mainContainer}>
        <div className={styles.thumbnailContainer}>
          <Crown />
        </div>
        <div className={styles.content}>
          <div className={styles.package}>{product.description}</div>
        </div>
        {message && <div>{message}</div>}
        <Checkout productId={productId} />
      </div>
    </div>
  );
};
