import React from "react";
import { Modal } from "../Modal";
import { modalConfiguration } from "./modalsConfiguration";
import { ModalType } from "./types";

type ModalProviderProps = {
  modalType: ModalType;
  isOpen: boolean;
  onDone: () => void;
  className?: string;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({
  modalType,
  isOpen,
  onDone,
  className,
}) => {
  const { title, text, buttonTitle, thumbnail, onClick } =
    modalConfiguration[modalType];

  const handleOnClick = () => {
    onClick();
    onDone();
  };

  const modalProps = {
    title,
    text,
    buttonTitle,
    thumbnail,
    backdrop: true,
    open: isOpen,
    onClick: handleOnClick,
    className,
  };

  return <Modal {...modalProps} />;
};
