import { AuthChangeEvent, Session } from "@supabase/supabase-js";
import { userAPI } from "features/profile/api";
import { authenticationAPI } from "../api/authenticationAPI";

/**
 * Creates an anonymous session
 */
export const handleAnonymousLogin = async (): Promise<Session | null> =>
  authenticationAPI.signInAnonymously();

/**
 * Type to represent valid sessions during anonymous to authenticated transition
 */
export type AnonymousToAuthenticatedSessions = {
  fromSession: Session;
  toSession: Session;
};

/**
 * Checks if this is a transition from anonymous to authenticated user
 * Returns both sessions if they represent a valid transition, null otherwise
 */
export const getAnonymousTransitionSessions = (
  currentSession: Session | null,
  newSession: Session | null,
  event: AuthChangeEvent,
): AnonymousToAuthenticatedSessions | null => {
  if (!currentSession || !newSession) return null;

  const isValidTransition =
    event === "SIGNED_IN" &&
    Boolean(currentSession.user.is_anonymous) &&
    newSession.user.id !== currentSession.user.id;

  if (!isValidTransition) return null;

  return {
    fromSession: currentSession,
    toSession: newSession,
  };
};

/**
 * Handles the transition from anonymous to authenticated user
 * Attempts to transfer data and handles any failures gracefully
 */
export const handleAnonymousToAuthenticatedTransition = async (
  fromSession: Session,
  toSession: Session,
): Promise<void> => {
  try {
    await userAPI.transferUserData({
      fromSession,
      toSession,
      fromUserId: fromSession.user.id,
      toUserId: toSession.user.id,
    });
    console.log("Successfully transferred user data from anonymous account");
  } catch (error) {
    console.error(
      "Failed to transfer user data from anonymous account:",
      error,
    );
    // We intentionally don't rethrow the error to allow the auth flow to continue
  }
};
