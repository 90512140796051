import { AuthChangeEvent, Session } from "@supabase/supabase-js";
import { supabaseAPI } from "../supabase";
import {
  getAnonymousTransitionSessions,
  handleAnonymousToAuthenticatedTransition,
} from "../helpers/anonymousHelper";

const isAnonymousFlow = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("flow") === "anonymous";
};

// Export for testing purposes
// eslint-disable-next-line import/no-mutable-exports
export let currentSession: Session | null = null;
// eslint-disable-next-line import/no-mutable-exports
export let isInitialized = false;

const listeners = new Set<
  (args: { session: Session | null; isInitialized: boolean }) => void
>();

const notifyListeners = () => {
  listeners.forEach((listener) =>
    listener({ session: currentSession, isInitialized }),
  );
};

const initialize = async () => {
  // Get initial session
  const {
    data: { session },
  } = await supabaseAPI.getSupabaseClient().auth.getSession();
  currentSession = session;

  // Handle anonymous mode during initialization
  if (!currentSession && isAnonymousFlow()) {
    const { data } = await supabaseAPI
      .getSupabaseClient()
      .auth.signInAnonymously();
  }

  // Set up auth subscription
  supabaseAPI.getSupabaseClient().auth.onAuthStateChange((event, session) => {
    checkAnonymousTransitionAndHandle(currentSession, session, event);

    currentSession = session;

    notifyListeners();
  });

  isInitialized = true;
  notifyListeners();
};

const checkAnonymousTransitionAndHandle = (
  currentSession: Session | null,
  session: Session | null,
  event: AuthChangeEvent,
) => {
  const anonymousTransitionSessions = getAnonymousTransitionSessions(
    currentSession,
    session,
    event,
  );
  if (anonymousTransitionSessions) {
    handleAnonymousToAuthenticatedTransition(
      anonymousTransitionSessions.fromSession,
      anonymousTransitionSessions.toSession,
    );
  }
};

export const authenticationAPI = {
  initialize,
  isInitialized,
  getCurrentSession: () => currentSession,
  isAnonymousUser: (session: Session | null) =>
    session?.user?.is_anonymous || false,
  isAnonymousFlow,

  isSessionValid: (session: Session | null) => {
    if (!session) return false;
    const now = Math.floor(Date.now() / 1000);
    return session.expires_at ? session.expires_at > now : false;
  },
  signOut: async () => {
    const { error } = await supabaseAPI.getSupabaseClient().auth.signOut();
    if (error) throw error;
  },
  subscribe: (
    listener: (args: {
      session: Session | null;
      isInitialized: boolean;
    }) => void,
  ) => {
    listeners.add(listener);
    listener({ session: currentSession, isInitialized });
    return () => listeners.delete(listener);
  },
  signInAnonymously: async () => {
    const { data, error } = await supabaseAPI
      .getSupabaseClient()
      .auth.signInAnonymously();
    if (error) throw error;
    return data.session;
  },
  isAuthenticated: (session: Session | null) => !!session,
};
