import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { List } from "../../features/story/components/List";
import styles from "./Stories.module.css";
import { FooterMenu } from "../../features/headerFooter/components/FooterMenu";
import { Header } from "../../components";
import { Button } from "../../components/shared/Button";
import { useStories } from "../../features/story/hooks/stories";
import { useHasBalanceRemaining } from "../../features/premium/hooks/balance";

export const Stories: React.FC = () => {
  const navigate = useNavigate();
  const [stories] = useStories();
  const noStories = useCallback(() => stories?.length === 0, [stories]);

  if (!stories) return null;

  return (
    <div className={styles.root}>
      {noStories() ? (
        <EmptyState />
      ) : (
        <List
          className={styles.list}
          onItemClick={(storyId) => navigate(`story/${storyId}`)}
        />
      )}
      <Header withMenu title="My Stories" fixedPosition withBackground />
      <FooterMenu className={styles.footer} fixedBottom />
    </div>
  );
};

const EmptyState: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.emptyStateRoot}>
      <div className={styles.title}>Welcome to Story A Day</div>
      <div className={styles.text}>
        ✨ Unleash your imagination! 🌟 Your story universe awaits, powered by
        AI magic! 🪄 Dragons 🐉, spaceships 🚀, or talking pandas 🐼? The
        possibilities are endless! 💫 Let's create something amazing together!
        📚 ⭐️ 🎨 ✍️
      </div>
      <Button
        onClick={() => navigate("create")}
        className={styles.createButton}
        fullWidth
        animated
      >
        ✨ Start Your Story ✍️
      </Button>
    </div>
  );
};
