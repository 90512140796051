import React from "react";
import { Header } from "features/headerFooter/components/Header";
import styles from "./PrivacyPolicy.module.css";

export const PrivacyPolicy: React.FC = () => {
  return (
    <div className={styles.root}>
      <Header withBackButton fixedPosition withBackground />
      <div className={styles.content}>
        <h1>Privacy Policy</h1>
        <p>Last updated: {new Date().toLocaleDateString()}</p>

        <section>
          <h2>1. Introduction</h2>
          <p>
            This Privacy Policy describes how we collect, use, and handle your
            personal information when you use our application. Our service is
            designed for parents to use with their children, with parents being
            the primary account holders and controllers of the information.
          </p>
        </section>

        <section>
          <h2>2. Information We Collect</h2>
          <p>We collect the following types of information:</p>
          <ul>
            <li>
              Basic profile information from Google (name, email, profile
              picture) for the parent account
            </li>
            <li>
              Usage data and analytics through our Business Intelligence (BI)
              solutions
            </li>
            <li>Content you create within the application</li>
            <li>
              Location data (if enabled) to provide personalized story
              experiences based on local weather conditions
            </li>
          </ul>
          <p>
            Note: Location data collection is optional and will only be used
            with your explicit consent to enhance your story experience with
            weather-based personalization.
          </p>
        </section>

        <section>
          <h2>3. How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul>
            <li>Authenticate and manage your account</li>
            <li>Provide and improve our services</li>
            <li>Display your content and profile information</li>
            <li>Communicate with you about our services</li>
            <li>Send marketing communications (with your consent)</li>
          </ul>
          <p>
            We do not share your personal information with third-party services.
            Your data is used exclusively for providing and improving our
            services.
          </p>
        </section>

        <section>
          <h2>4. Data Retention</h2>
          <p>
            We retain your data based on your account status and our business
            needs:
          </p>
          <ul>
            <li>
              For premium users: Data is retained as long as your premium
              subscription is active
            </li>
            <li>
              For non-premium users: Data may be retained for a limited period
              based on our business decisions
            </li>
            <li>
              You can request data deletion at any time through your account
              settings or by contacting us
            </li>
          </ul>
        </section>

        <section>
          <h2>5. Google Authentication</h2>
          <p>
            We use Google Authentication to provide a secure and convenient
            login experience. When you sign in with Google, we receive basic
            profile information that you have made available in your Google
            account settings.
          </p>
        </section>

        <section>
          <h2>6. Data Protection</h2>
          <p>
            We implement appropriate security measures to protect your personal
            information. However, no method of transmission over the internet is
            100% secure, and we cannot guarantee absolute security.
          </p>
        </section>

        <section>
          <h2>7. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate information</li>
            <li>Request deletion of your information</li>
            <li>Opt-out of marketing communications</li>
          </ul>
          <p>To exercise these rights:</p>
          <ul>
            <li>
              For data deletion requests: Send an email to info@storyaday.ai
              with your request
            </li>
            <li>
              For marketing communications: Use the unsubscribe link in any
              marketing email you receive
            </li>
          </ul>
          <p>
            We will process your requests within a reasonable timeframe and
            confirm once completed.
          </p>
        </section>

        <section>
          <h2>8. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at info@storyaday.ai.
          </p>
        </section>
      </div>
    </div>
  );
};
