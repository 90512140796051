import React, { useState } from "react";
import { useSeeds } from "features/storyCreation/hooks";
import { useHasBalanceRemaining } from "features/premium/hooks/balance";
import { Session } from "@supabase/supabase-js";
import styles from "./Create.module.css";
import { TextArea } from "../../components/shared/TextArea";
import { CardCarousel } from "../../features/storyCreation/components";
import { Button } from "../../components/shared/Button";
import { useAuth } from "../../features/authentication";
import { Header } from "../../features/headerFooter/components/Header/Header";
import { queryClient } from "../..";
import { useGlobalModalContext } from "../../features/modal/components/GlobalModal/GlobalModal";
import { SectionTitle } from "./components/SectionTitle";
import { storyAPI } from "../../features/story/api/storyAPI";
import { useNavigationGuard } from "../../features/navigation";
import { delay, createStoryRequest, seedToCardItem, getStatus } from "./utils";
import { Seed } from "../../App/types";

type Step = "story" | "characters";
type CarouselItemType = "seed" | "style" | "character";
type CarouselItem = { type: "item"; id: string } | { type: "add" };
type CardItem = {
  id: string;
  title: string;
  thumbnail: string;
  type: "activity" | "character" | "location";
};

// Types
interface ButtonConfig {
  type: string;
  text: string;
  onClick: () => Promise<void> | void;
}

interface StoryFormData {
  about: string;
  characters: string;
  selectedSeeds: string[];
}

// Hooks
const useStoryForm = () => {
  const [formData, setFormData] = useState<StoryFormData>({
    about: "",
    characters: "",
    selectedSeeds: [],
  });

  const updateFormData = (
    field: keyof StoryFormData,
    value: string | string[],
  ) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const hasContent =
    formData.about.length > 0 ||
    formData.characters.length > 0 ||
    formData.selectedSeeds.length > 0;

  return { formData, updateFormData, hasContent };
};

const useStoryCreation = (formData: StoryFormData, session: Session | null) => {
  const { showModal } = useGlobalModalContext();
  const [disabled, setDisabled] = useState(false);

  const createStory = async () => {
    const request = createStoryRequest(
      formData.about,
      formData.characters,
      formData.selectedSeeds as unknown as Seed[],
    );

    try {
      await storyAPI.create(request, session);
      await queryClient.invalidateQueries("storiesData");
      await delay(300);
      showModal("storySuccess");
    } catch (error) {
      console.error("Failed to create story:", error);
      // TODO: Show error modal
    } finally {
      setDisabled(false);
    }
  };

  const handleCreate = async () => {
    setDisabled(true);
    try {
      await createStory();
    } finally {
      setDisabled(false);
    }
  };

  return { disabled, handleCreate };
};

// Components
const StoryStep: React.FC<{
  formData: StoryFormData;
  updateFormData: (
    field: keyof StoryFormData,
    value: string | string[],
  ) => void;
  handleCarouselClick: (item: CarouselItem, type: CarouselItemType) => void;
  seedCardItems: CardItem[];
}> = ({ formData, updateFormData, handleCarouselClick, seedCardItems }) => (
  <>
    <div className={styles.section}>
      <SectionTitle title="Your Adventure" />
      <h2 className={styles.subtitle}>
        What magical world will your story take us to? The more you provide, the
        richer your story will be!
      </h2>
      <div className={styles.textAreaContainer}>
        <TextArea
          value={formData.about}
          className={styles.textArea}
          minHeight="150px"
          onChange={(event) => updateFormData("about", event.target.value)}
          placeholder="Describe it here!"
        />
      </div>
    </div>

    {seedCardItems && (
      <div className={styles.section}>
        <SectionTitle title="Tale Ingredients" />
        <h2 className={styles.subtitle}>
          Choose some magical ingredients to sprinkle throughout your tale!
        </h2>
        <CardCarousel
          selected={formData.selectedSeeds}
          onClick={(item) => handleCarouselClick(item, "seed")}
          className={styles.carousel}
          items={seedCardItems.filter((item) => item.type === "activity")}
        />
      </div>
    )}
  </>
);

const CharacterStep: React.FC<{
  formData: StoryFormData;
  updateFormData: (
    field: keyof StoryFormData,
    value: string | string[],
  ) => void;
  handleCarouselClick: (item: CarouselItem, type: CarouselItemType) => void;
  seedCardItems: CardItem[];
}> = ({ formData, updateFormData, handleCarouselClick, seedCardItems }) => (
  <>
    <div className={styles.section}>
      <SectionTitle title="Introduce Your Heroes" />
      <h2 className={styles.subtitle}>
        Who will be the stars of your epic adventure? Share their names, age,
        hair color, eye color, and any other details that make them unique!
      </h2>
      <div className={styles.textAreaContainer}>
        <TextArea
          value={formData.characters}
          className={styles.textArea}
          minHeight="150px"
          onChange={(event) => updateFormData("characters", event.target.value)}
          placeholder="Describe it here!"
        />
      </div>
    </div>
    {seedCardItems && (
      <div className={styles.section}>
        <SectionTitle title="Your Sidekicks" />
        <h2 className={styles.subtitle}>
          Time to recruit some trusty companions! Pick characters to join the
          fun!
        </h2>
        <CardCarousel
          selected={formData.selectedSeeds}
          onClick={(item) => handleCarouselClick(item, "seed")}
          className={styles.carousel}
          items={seedCardItems.filter((item) => item.type === "character")}
        />
      </div>
    )}
  </>
);

const NavigationButtons: React.FC<{
  currentStep: Step;
  buttonConfig: ButtonConfig;
  disabled: boolean;
  hasContent: boolean;
  onBack: () => void;
  onNext: () => void;
}> = ({ currentStep, buttonConfig, disabled, hasContent, onBack, onNext }) => (
  <div className={styles.navigationButtons}>
    {currentStep === "characters" ? (
      <Button className={styles.backButton} text="Back" onClick={onBack} />
    ) : (
      <div className={styles.backButton} />
    )}
    {currentStep === "story" ? (
      <Button
        className={styles.nextButton}
        text="Next"
        onClick={onNext}
        disabled={!hasContent}
      />
    ) : (
      <Button
        className={styles.createButton}
        disabled={disabled || !hasContent}
        text={buttonConfig.text}
        onClick={buttonConfig.onClick}
      />
    )}
  </div>
);

// Main Component
export const Create = () => {
  const { session, isAnonymous } = useAuth();
  const [loadingBalance, hasBalanceRemaining] = useHasBalanceRemaining();
  const navigationGuard = useNavigationGuard();
  const [currentStep, setCurrentStep] = useState<Step>("story");
  const [seeds] = useSeeds();
  const { showModal } = useGlobalModalContext();

  const { formData, updateFormData, hasContent } = useStoryForm();
  const { disabled, handleCreate } = useStoryCreation(formData, session);

  const getButtonConfig = (): ButtonConfig => {
    const currentStatus = getStatus(
      loadingBalance,
      isAnonymous,
      hasBalanceRemaining,
    );

    switch (currentStatus) {
      case "loading":
        return { type: "loading", text: "Create", onClick: () => {} };
      case "anonymous-credit":
      case "authenticated-credit":
        return { type: "create-story", text: "Create", onClick: handleCreate };
      case "anonymous-no-credit":
        return {
          type: "reditect-signup",
          text: "Create",
          onClick: () => showModal("anonymousSignup"),
        };
      case "authenticated-no-credit":
        return {
          type: "no-credit",
          text: "Buy Credit",
          onClick: () => navigationGuard.to("package"),
        };
      default:
        return { type: "default", text: "Create", onClick: () => {} };
    }
  };

  const handleCarouselClick = (item: CarouselItem, type: CarouselItemType) => {
    if (item.type === "add") {
      console.log("add new character");
      return;
    }
    if (type === "seed") {
      const newSelectedSeeds = formData.selectedSeeds.includes(item.id)
        ? formData.selectedSeeds.filter((seedId) => seedId !== item.id)
        : [...formData.selectedSeeds, item.id];
      updateFormData("selectedSeeds", newSelectedSeeds);
    }
  };

  const handleNextStep = () => {
    if (currentStep === "story") {
      setCurrentStep("characters");
    }
  };

  const handleBackStep = () => {
    if (currentStep === "characters") {
      setCurrentStep("story");
    }
  };

  const seedCardItems = seeds?.map(seedToCardItem) ?? [];
  const buttonConfig = getButtonConfig();

  return (
    <div className={styles.root}>
      <Header withBackButton />
      <h1 className={styles.mainTitle}>Create Your Story</h1>
      <div className={styles.contentContainer}>
        <div className={styles.sectionsContainer}>
          {currentStep === "story" ? (
            <StoryStep
              formData={formData}
              updateFormData={updateFormData}
              handleCarouselClick={handleCarouselClick}
              seedCardItems={seedCardItems}
            />
          ) : (
            <CharacterStep
              formData={formData}
              updateFormData={updateFormData}
              handleCarouselClick={handleCarouselClick}
              seedCardItems={seedCardItems}
            />
          )}
        </div>
        <NavigationButtons
          currentStep={currentStep}
          buttonConfig={buttonConfig}
          disabled={disabled}
          hasContent={hasContent}
          onBack={handleBackStep}
          onNext={handleNextStep}
        />
      </div>
    </div>
  );
};
