import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationGuard } from "../navigation";
import { useAuth } from "../../authentication";

export const useNavigationGuard = () => {
  const navigate = useNavigate();
  const { session } = useAuth();

  const navigationGuard = useMemo(() => {
    return new NavigationGuard(navigate, session);
  }, [navigate, session]);

  return navigationGuard;
};
