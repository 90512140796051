import { ReactComponent as Successthumbnail } from "@assets/success-thumbnail.svg";
import { ModalConfiguration } from "../../types";

export const modalConfiguration: ModalConfiguration = {
  storySuccess: {
    title: "Story Fairies at Work! ✨",
    text: "🪄 Our magical quills are hard at work! Head to your stories to watch your tale come to life... 📝✨",
    buttonTitle: "Watch It Unfold",
    thumbnail: <Successthumbnail />,
    onClick: () => {},
    navigateTo: "stories",
    autoNavigateAfter: 5000,
  },
  storyError: {
    title: "Magic Glitch!",
    text: "🚀✨  Uh-oh, our magic wand took a nap! 💤🔮 Try again or give us a silly dance for luck! 🤪🎉",
    buttonTitle: "Try Again",
    thumbnail: <Successthumbnail />,
    onClick: () => {},
    navigateTo: "create",
  },
  paymentSuccess: {
    title: "Payment Successfull!",
    text: "🎉✨ Credits have been added to your account. Get ready for magical adventures! 🧙‍♂️📚",
    buttonTitle: "Let's Go!",
    thumbnail: <Successthumbnail />,
    onClick: () => {},
    navigateTo: "create",
  },
  paymentError: {
    title: "Oops!",
    text: "🙈 It seems our magic trick misfired! Your payment didn't go through this time, but no worries, let's try that again!",
    buttonTitle: "Try Again",
    thumbnail: <Successthumbnail />,
    onClick: () => {},
    navigateTo: "package",
  },
  anonymousSignup: {
    title: "Don't Let Your Stories Vanish! ✨",
    text: "🎭 Your magical stories will disappear unless you save them! Sign up now to keep your enchanting tales safe in your personal collection... 📚✨",
    buttonTitle: "Sign Up to Save Stories",
    thumbnail: <Successthumbnail />,
    onClick: () => {},
    navigateTo: "signup",
  },
};
