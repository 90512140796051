import { Session } from "@supabase/supabase-js";
import {
  isPaymentStatusResponse,
  PaymentStatus,
  PaymentStatusResponse,
} from "./types";

const API_ROUTE = process.env.REACT_APP_API_ROUTE;

const getPaymentStatus = async (paymentSessionId: string, session: Session) => {
  const response = await fetch(`${API_ROUTE}/api/get-payment-status`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ paymentSessionId, session }),
  });
  const responseData = await response.json();

  if (!isPaymentStatusResponse(responseData)) {
    throw new Error("Invalid payment status response");
  }

  return paymentResponseToPaymentStatus(responseData);
};

const paymentResponseToPaymentStatus = (
  response: PaymentStatusResponse,
): PaymentStatus => {
  return response.status === "complete" && response.payment_status === "paid"
    ? "success"
    : "failed";
};

export const paymentsAPI = {
  getPaymentStatus,
};
