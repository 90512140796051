import { useEffect, useState } from "react";
import { useAuth } from "../../authentication";
import { balanceAPI } from "../apis";

export const useBalance = () => {
  const { session } = useAuth();
  const [balance, setBalance] = useState<number>(0);

  useEffect(() => {
    if (session) {
      balanceAPI
        .getRamaining(session)
        .then((balance) => {
          setBalance(balance);
        })
        .catch((error) => console.error("Error fetching balance:", error));
    }
  }, [session]);

  return balance;
};

export const useHasBalanceRemaining = () => {
  const { session } = useAuth();
  const [balance, setBalance] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (session) {
      balanceAPI
        .getRamaining(session)
        .then((balance) => {
          setBalance(balance);
          setLoading(false);
        })
        .catch((error) => console.error("Error fetching balance:", error));
    }
  }, [session]);

  return [loading, balance > 0];
};
