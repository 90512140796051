import React from "react";
import cn from "classnames";
import styles from "./Button.module.css";

interface ButtonProps {
  text?: string;
  onClick: () => void;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  animated?: boolean;
  fullWidth?: boolean;
}

export const Button = ({
  text,
  onClick,
  className,
  children,
  disabled,
  animated,
  fullWidth,
}: ButtonProps) => (
  <button
    type="button"
    className={cn(
      styles.root,
      {
        [styles.disabled]: disabled,
        [styles.animated]: animated,
        [styles.fullWidth]: fullWidth,
      },
      className,
    )}
    onClick={() => !disabled && onClick()}
    disabled={disabled}
  >
    {text || children}
  </button>
);
