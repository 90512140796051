import { loadStripe } from "@stripe/stripe-js";

const {
  REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST,
  REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE,
} = process.env;

console.log("Environment:", process.env.NODE_ENV);
console.log("Test Key:", REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST);
console.log("Live Key:", REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE);

const REACT_APP_STRIPE_PUBLISHABLE_KEY =
  process.env.NODE_ENV === "development"
    ? REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
    : REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE;

if (!REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  throw new Error("Stripe public key is not set");
}

export const stripe = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);
