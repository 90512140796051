import { useEffect, useState } from "react";
import { Product } from "../../../App/types";
import { useAuth } from "../../authentication";
import { productAPI } from "../apis";

export const useProducts = () => {
  const { session, isInitialized } = useAuth();
  const [products, setProducts] = useState<Product[] | null>(null);

  useEffect(() => {
    if (session && isInitialized) {
      productAPI
        .getAllProducts(session)
        .then((products) => {
          setProducts(products);
        })
        .catch((error) => console.error("Error fetching products:", error));
    }
  }, [session, isInitialized]);

  return products;
};

export const useProduct = (productId?: string) => {
  const { session, isInitialized } = useAuth();
  const [product, setProduct] = useState<Product | undefined>(undefined);

  useEffect(() => {
    if (session && isInitialized) {
      productAPI
        .getAllProducts(session)
        .then((products: Product[]) => {
          setProduct(products.find((product) => product.id === productId));
        })
        .catch((error) => console.error("Error fetching products:", error));
    }
  }, [session, productId, isInitialized]);

  return product;
};
