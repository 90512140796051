import { Session } from "@supabase/supabase-js";

const API_ROUTE = process.env.REACT_APP_API_ROUTE;

interface TransferUserDataParams {
  fromSession: Session;
  toSession: Session;
  fromUserId: string;
  toUserId: string;
}

interface TransferUserDataResponse {
  message: string;
}

const transferUserData = async ({
  fromSession,
  toSession,
  fromUserId,
  toUserId,
}: TransferUserDataParams): Promise<TransferUserDataResponse> => {
  try {
    const response = await fetch(`${API_ROUTE}/api/transfer_user_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        session: toSession,
        fromSession,
        toSession,
        fromUserId,
        toUserId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to transfer user data:", error);
    throw error;
  }
};

export const userAPI = {
  transferUserData,
};
