import { NavigateFunction } from "react-router-dom";
import { Session } from "@supabase/supabase-js";
import { RouteDefinition, NavigationRule } from "./types";
import { createRouteConfig, RouteId } from "./config";

export class NavigationGuard {
  private navigate: NavigateFunction;

  private session: Session | null;

  private routes: Record<RouteId, RouteDefinition>;

  constructor(navigate: NavigateFunction, session: Session | null) {
    this.navigate = navigate;
    this.session = session;
    this.routes = createRouteConfig(session);
  }

  to = async (
    routeId: RouteId,
    params: Record<string, string> = {},
    shouldReplace = true,
  ): Promise<void> => {
    const route = this.routes[routeId];
    if (!route) {
      console.warn(`No route found for: ${routeId}`);
      return;
    }

    // Check rules in priority order
    const sortedRules = [...route.rules].sort(
      (a, b) => b.priority - a.priority,
    );

    const matchingRule = await sortedRules.reduce<
      Promise<NavigationRule | null>
    >(async (acc, rule) => {
      const found = await acc;
      if (found) return found;

      const shouldRedirect = await rule.condition(this.session);

      return shouldRedirect ? rule : null;
    }, Promise.resolve(null));

    if (matchingRule) {
      const redirectRoute = this.routes[matchingRule.redirect];
      await this.navigate(redirectRoute.path, { replace: shouldReplace });
      return;
    }

    // If all rules pass, navigate to destination
    const destination = route.getDestination
      ? route.getDestination(params)
      : route.path;

    await this.navigate(destination, { replace: shouldReplace });
  };
}
