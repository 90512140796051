import { useState } from "react";

export const usePageNavigation = (totalPages: number) => {
  const [currentPage, setCurrentPage] = useState(0);
  const isLastPage = currentPage === totalPages - 1;

  const goToNextPage = () =>
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));

  const goToPrevPage = () =>
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));

  return {
    currentPage,
    isLastPage,
    goToNextPage,
    goToPrevPage,
    setCurrentPage,
  };
};
