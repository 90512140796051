export type PaymentStatusResponse = {
  status: "complete" | "expired" | "open";
  payment_status: "no_payment_required" | "paid" | "unpaid";
};

export const isPaymentStatusResponse = (
  value: unknown,
): value is PaymentStatusResponse => {
  if (!value || typeof value !== "object") return false;

  const response = value as Record<string, unknown>;
  if (!("status" in response) || !("payment_status" in response)) return false;

  return (
    ["complete", "expired", "open"].includes(response.status as string) &&
    ["no_payment_required", "paid", "unpaid"].includes(
      response.payment_status as string,
    )
  );
};

export type PaymentStatus = "success" | "failed";
