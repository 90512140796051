import React, { useEffect, useState } from "react";
import cn from "classnames";
import splashImage from "@assets/splash-screen.jpg";
import { useNavigationGuard } from "../../features/navigation";
import { splashScreenAPI } from "../../features/splash/api/splashScreenAPI";
import { useAuth } from "../../features/authentication";
import styles from "./SplashScreen.module.css";

const MINIMUM_DISPLAY_TIME = 3000;
const IMAGE_LOAD_TIMEOUT = 3000;

export const SplashScreen: React.FC = () => {
  const navigationGuard = useNavigationGuard();
  const { session } = useAuth();
  const [isExiting, setIsExiting] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Safety timeout for image loading
    const safetyTimer = setTimeout(() => {
      if (!imageLoaded) {
        // If image hasn't loaded within timeout, skip splash screen
        splashScreenAPI.markAsSeen();
        const intendedPath = splashScreenAPI.getIntendedPath();
        splashScreenAPI.clearIntendedPath();
        navigationGuard.to(session ? "stories" : "login");
      }
    }, IMAGE_LOAD_TIMEOUT);

    return () => clearTimeout(safetyTimer);
  }, [imageLoaded, navigationGuard, session]);

  useEffect(() => {
    // Only start the display timer once the image is loaded
    if (imageLoaded) {
      const splashTimer = setTimeout(() => {
        setIsExiting(true);
      }, MINIMUM_DISPLAY_TIME);

      return () => clearTimeout(splashTimer);
    }
    return undefined;
  }, [imageLoaded]);

  useEffect(() => {
    if (isExiting) {
      const exitTimer = setTimeout(() => {
        splashScreenAPI.markAsSeen();
        const intendedPath = splashScreenAPI.getIntendedPath();
        splashScreenAPI.clearIntendedPath();
        navigationGuard.to(session ? "stories" : "login");
      }, 500);

      return () => clearTimeout(exitTimer);
    }
    return undefined;
  }, [isExiting, navigationGuard, session]);

  return (
    <div className={cn(styles.splashPage, { [styles.exit]: isExiting })}>
      <img
        className={cn(styles.splashImage, { [styles.loaded]: imageLoaded })}
        src={splashImage}
        alt="Splash Screen"
        onLoad={() => setImageLoaded(true)}
      />
      <div
        className={cn(styles.splashContent, { [styles.loaded]: imageLoaded })}
      >
        {!imageLoaded && (
          <div className={styles.loadingIndicator}>
            <div className={styles.loadingSpinner} />
          </div>
        )}
      </div>
    </div>
  );
};
