import React from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigationGuard } from "features/navigation";
import styles from "./App.module.css";
import { useAuth } from "../features/authentication";
import { GlobalModal } from "../features/modal/components/GlobalModal/GlobalModal";
import { useSeeds } from "../features/storyCreation";
import { AssetPreloader } from "../features/assets";
import {
  createRouteConfig,
  getRoutes,
  getFallbackRoute,
} from "../features/navigation/config";
import { ProtectedRoute } from "../features/navigation/components/ProtectedRoute";

export const App: React.FC = () => {
  const { session } = useAuth();
  useSeeds(); // used to prefetch data

  const routeConfig = createRouteConfig(session);
  const routes = getRoutes(routeConfig);
  const fallbackRoute = getFallbackRoute(routeConfig);
  const navigationGuard = useNavigationGuard();

  return (
    <div className={styles.appContainer}>
      <div className={styles.root}>
        <AssetPreloader />
        <GlobalModal onNavigate={navigationGuard.to}>
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ProtectedRoute route={route}>{route.element}</ProtectedRoute>
                }
              />
            ))}
            <Route path="*" element={fallbackRoute.element} />
          </Routes>
        </GlobalModal>
      </div>
    </div>
  );
};
