import React, { useState, useEffect } from "react";
import { ReactComponent as Crown } from "@assets/crown.svg";
import { useLocation } from "react-router-dom";

import { paymentsAPI } from "features/payment";
import styles from "./PaymentCompletion.module.css";
import { useAuth } from "../../features/authentication";
import { Button } from "../../components/shared/Button";
import { useNavigationGuard } from "../../features/navigation";

import { Header } from "../../components";

export const PaymentCompletion = () => {
  const { session } = useAuth();
  const paymentSessionId = usePaymentSessionId();
  const location = useLocation();
  const navigationGuard = useNavigationGuard();

  const [paymentStatus, setPaymentStatus] = useState<
    "loading" | "success" | "failed"
  >("loading");

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (!paymentSessionId || !session) return;

      try {
        const status = await paymentsAPI.getPaymentStatus(
          paymentSessionId,
          session,
        );
        setPaymentStatus(status);
      } catch (error) {
        console.error("Failed to check payment status:", error);
        setPaymentStatus("failed");
      }
    };

    checkPaymentStatus();
  }, [paymentSessionId, session]);

  const handleTryAgain = () => {
    // Get the product ID from the URL and redirect to payment
    const productId = new URLSearchParams(location.search).get("product_id");
    if (productId) {
      navigationGuard.to("payment", { slug: productId });
    } else {
      navigationGuard.to("package");
    }
  };

  const handleCreateStory = () => {
    navigationGuard.to("create");
  };

  const renderContent = () => {
    // Don't render anything while loading
    if (paymentStatus === "loading") {
      return null;
    }

    switch (paymentStatus) {
      case "success":
        return (
          <div className={styles.message}>
            <h2>Payment Successful! 🎉</h2>
            <p>
              Your credits have been added to your account. Ready to create your
              magical story?
            </p>
            <Button
              fullWidth
              text="Create Your Story"
              onClick={handleCreateStory}
              className={styles.actionButton}
            />
          </div>
        );
      case "failed":
        return (
          <div className={styles.message}>
            <h2>Payment Failed 😕</h2>
            <p>
              We couldn't process your payment. Don't worry, you can try again!
            </p>
            <Button
              fullWidth
              text="Try Again"
              onClick={handleTryAgain}
              className={styles.actionButton}
            />
          </div>
        );
      default:
        return (
          <div className={styles.message}>
            <h2>Something went wrong</h2>
            <p>Please try again later.</p>
            <Button
              fullWidth
              text="Go Back"
              onClick={() => navigationGuard.to("package")}
              className={styles.actionButton}
            />
          </div>
        );
    }
  };

  return (
    <div className={styles.root}>
      <Header fixedPosition withBackButton />
      <div className={styles.mainContainer}>
        <div className={styles.thumbnailContainer}>
          <Crown />
        </div>
        <div className={styles.content}>{renderContent()}</div>
      </div>
    </div>
  );
};

function usePaymentSessionId() {
  const queryParams = new URLSearchParams(useLocation().search);
  return queryParams.get("session_id");
}
