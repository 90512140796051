interface AssetConfig {
  path: string;
  type: "image" | "font" | "style" | "script";
  format?: string;
  crossOrigin?: boolean;
}

export const preloadAssets: AssetConfig[] = [
  // Add assets here as needed
];

// Registry for dynamically added assets
let dynamicAssets: AssetConfig[] = [];

export const assetRegistry = {
  register: (asset: AssetConfig) => {
    if (!dynamicAssets.some((a) => a.path === asset.path)) {
      dynamicAssets.push(asset);
    }
  },
  unregister: (path: string) => {
    dynamicAssets = dynamicAssets.filter((a) => a.path !== path);
  },
  getDynamicAssets: () => [...dynamicAssets],
  getAllAssets: () => [...preloadAssets, ...dynamicAssets],
};
