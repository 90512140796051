import React, { useCallback } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { stripe } from "./stripe";
import { useAuth } from "../authentication";

const API_ROUTE = process.env.REACT_APP_API_ROUTE;

interface CheckoutProps {
  productId: string;
}

export const Checkout = ({ productId }: CheckoutProps) => {
  const { session } = useAuth();
  const fetchClientSecret = useCallback(() => {
    if (!session) {
      return Promise.resolve(null);
    }

    return fetch(`${API_ROUTE}/api/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        session,
        userId: session.user.id,
        productId,
      }),
    })
      .then((response) => response.json())
      .then((json) => json.clientSecret);
  }, [session, productId]);

  if (!session) {
    return null;
  }

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripe} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};
