import { Session } from "@supabase/supabase-js";
import { useQuery } from "react-query";
import { useAuth } from "../../authentication";
import { seedAPI } from "../api";
import { Seed } from "../../../App/types";

const REFETCH_INTERVAL = 60 * 60 * 1000;

const fetchSeeds = async (session: Session): Promise<Seed[] | undefined> =>
  seedAPI.getAll(session);

export const useSeeds = () => {
  const { session, isInitialized } = useAuth();
  const { data, isLoading, error, refetch } = useQuery(
    "seedsData",
    () => (session ? fetchSeeds(session) : undefined),
    {
      refetchInterval: REFETCH_INTERVAL,
      enabled: Boolean(session && isInitialized),
    },
  );

  return [data, isLoading, error, refetch] as const;
};
