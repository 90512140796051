import { SpringRef } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";

const TRESHOLD = 30;

const PAGE_OUT_OF_SCREEN = {
  x: -1000,
  y: 0,
  rot: -3,
  scale: 1.1,
};
const PAGE_CENTER = {
  x: 0,
  y: 0,
  rot: 0,
  scale: 1,
};

export const usePageDrag = (
  currentPage: number,
  isLastPage: boolean,
  goToNextPage: () => void,
  goToPrevPage: () => void,
  springApi: SpringRef<{
    x: number;
    y: number;
    rot: number;
    scale: number;
    zIndex: number;
  }>,
) => {
  let handelingPage: "current" | "previous" | null = null;
  return useDrag(
    ({ swipe: [swipeX], active, velocity: [vx], movement: [mx] }) => {
      const isDraggingLeft = mx < 0;
      const isDraggingRight = mx > 0;

      springApi.start((i) => {
        const isCurrentPage = currentPage === i;
        const isPrevPage = currentPage - 1 === i;

        if (
          !handelingPage &&
          (isDraggingLeft || (isDraggingRight && isPrevPage))
        ) {
          handelingPage = isDraggingLeft ? "current" : "previous";
        }

        if (i < currentPage && !(isPrevPage && handelingPage === "previous")) {
          return PAGE_OUT_OF_SCREEN;
        }

        if (isCurrentPage && handelingPage === "current") {
          if (swipeX === -1 && !isLastPage) {
            goToNextPage();
            handelingPage = null;
            return PAGE_OUT_OF_SCREEN;
          }

          if (isDraggingLeft) {
            if (active) {
              return { x: mx * 2, rot: mx / 200, scale: 1.1 };
            }
            handelingPage = null;
            if (!isLastPage && mx < -100) {
              goToNextPage();
              return PAGE_OUT_OF_SCREEN;
            }
            return PAGE_CENTER;
          }

          if (isDraggingRight) {
            if (active) {
              return PAGE_CENTER;
            }
            handelingPage = null;
            return PAGE_CENTER;
          }
        }

        if (isPrevPage && handelingPage === "previous") {
          if (swipeX === 1) {
            goToPrevPage();
            handelingPage = null;
            return PAGE_CENTER;
          }

          if (isDraggingRight) {
            if (active) {
              return {
                x: Math.min(-500 + mx, 0),
                rot: Math.min(-3 + mx / 200, 0),
                scale: 1.1,
              };
            }
            handelingPage = null;
            goToPrevPage();
            return PAGE_CENTER;
          }

          if (isDraggingLeft) {
            if (active) {
              return {
                x: Math.min(-500 + mx, 0),
                rot: Math.min(-3 + mx / 200, 0),
                scale: 1.1,
              };
            }
            handelingPage = null;
            goToPrevPage();
            return PAGE_CENTER;
          }
        }
        return {
          x: 0,
          y: 0,
          rot: 0,
          scale: 1,
        };
      });
    },
    { threshold: [TRESHOLD, 0], axis: "x" },
  );
};
