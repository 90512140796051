import React from "react";
import { Session } from "@supabase/supabase-js";
import { Signup } from "pages/Signup/Signup";
import { Story } from "../../../pages/Story";
import { Payment } from "../../../pages/Payment";
import { Create } from "../../../pages/Create";
import { Stories } from "../../../pages/Stories";
import { PackagePicker } from "../../../pages/PackagePicker";
import { Profile } from "../../../pages/Profile";
import { Main } from "../../../pages/Main";
import { Login } from "../../../pages/Login";
import { PaymentCompletion } from "../../../pages/PaymentCompletion";
import { SplashScreen } from "../../../pages/SplashScreen";
import { PrivacyPolicy } from "../../../pages/PrivacyPolicy/PrivacyPolicy";
import { splashScreenAPI } from "../../splash";
import { authenticationAPI } from "../../authentication/api";
import { RouteDefinition } from "../types";
import { RouteIds, RouteId } from "./routes";
import {
  redirectToSplashIfNotSeen,
  redirectToLoginIfNoSession,
  redirectIfAnonymousUser,
  // redirectIfNoCredits,
} from "./rules";

// Base route configuration - the single source of truth
export const ROUTES: Record<RouteId, RouteDefinition> = {
  [RouteIds.splash]: {
    path: "/splash",
    element: <SplashScreen />,
    rules: [
      {
        priority: 100,
        condition: async (session: Session | null): Promise<boolean> => {
          if (!splashScreenAPI.hasSeenSplash()) {
            return false; // Don't redirect, show splash screen
          }
          return true; // Has seen splash, redirect based on session
        },
        redirect: RouteIds.stories,
      },
    ],
  },
  [RouteIds.stories]: {
    path: "/",
    element: <Stories />,
    rules: [redirectToSplashIfNotSeen("/"), redirectToLoginIfNoSession()],
  },
  [RouteIds.login]: {
    path: "/login",
    element: <Login />,
    rules: [
      {
        priority: 1,
        condition: async (session: Session | null): Promise<boolean> =>
          authenticationAPI.isAuthenticated(session),
        redirect: RouteIds.stories,
      },
    ],
  },
  [RouteIds.signup]: {
    path: "/signup",
    element: <Signup />,
    rules: [
      {
        priority: 1,
        condition: async (session: Session | null): Promise<boolean> =>
          authenticationAPI.isAuthenticated(session) &&
          !authenticationAPI.isAnonymousUser(session),
        redirect: RouteIds.stories,
      },
    ],
  },
  [RouteIds.main]: {
    path: "/main",
    element: <Main />,
    rules: [
      redirectToSplashIfNotSeen("/main"),
      redirectToLoginIfNoSession(),
      redirectIfAnonymousUser(),
    ],
  },
  [RouteIds.payment]: {
    path: "/payment/:slug",
    element: <Payment />,
    rules: [
      redirectToSplashIfNotSeen("/payment/:slug"),
      redirectToLoginIfNoSession(),
      redirectIfAnonymousUser(),
    ],
    getDestination: (params: Record<string, string>) =>
      `/payment/${params.slug}`,
  },
  [RouteIds.paymentCompletion]: {
    path: "/payment-completion",
    element: <PaymentCompletion />,
    rules: [redirectToLoginIfNoSession()],
  },
  [RouteIds.package]: {
    path: "/package",
    element: <PackagePicker />,
    rules: [
      redirectToSplashIfNotSeen("/package"),
      redirectToLoginIfNoSession(),
      redirectIfAnonymousUser(),
    ],
  },
  [RouteIds.story]: {
    path: "/story/:slug",
    element: <Story />,
    rules: [
      redirectToSplashIfNotSeen("/story/:slug"),
      redirectToLoginIfNoSession(),
    ],
  },
  [RouteIds.create]: {
    path: "/create",
    element: <Create />,
    rules: [
      redirectToSplashIfNotSeen("/create"),
      redirectToLoginIfNoSession(),
      // redirectIfNoCredits(),
    ],
  },
  [RouteIds.profile]: {
    path: "/profile",
    element: <Profile />,
    rules: [
      redirectToSplashIfNotSeen("/profile"),
      redirectToLoginIfNoSession(),
    ],
  },
  [RouteIds.privacyPolicy]: {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    rules: [], // No rules means it's publicly accessible
  },
};

// Function to create the route configuration with session-dependent rules
export const createRouteConfig = (
  session: Session | null,
): Record<RouteId, RouteDefinition> => {
  return Object.entries(ROUTES).reduce(
    (acc, [id, route]) => ({
      ...acc,
      [id]: {
        ...route,
        rules: route.rules.map((rule) => ({
          ...rule,
          condition: async () => rule.condition(session),
        })),
      },
    }),
    {} as Record<RouteId, RouteDefinition>,
  );
};

// Helper to get routes array for React Router
export const getRoutes = (config: Record<RouteId, RouteDefinition>) =>
  Object.values(config);

// Helper to get fallback route
export const getFallbackRoute = (config: Record<RouteId, RouteDefinition>) => ({
  path: config.stories.path,
  element: config.stories.element,
  rules: config.stories.rules,
});

// Derive types from the route configuration
export type RoutePaths = (typeof ROUTES)[RouteId]["path"];
