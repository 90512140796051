import React from "react";
import { useNavigationGuard } from "features/navigation";
import styles from "./Footer.module.css";

export const Footer: React.FC<{ className?: string }> = ({ className }) => {
  const navigationGuard = useNavigationGuard();

  return (
    <footer className={`${styles.root} ${className || ""}`}>
      <div className={styles.content}>
        <button
          type="button"
          className={styles.link}
          onClick={() => navigationGuard.to("privacyPolicy", {}, false)}
        >
          Privacy Policy
        </button>
      </div>
    </footer>
  );
};
