import { Session } from "@supabase/supabase-js";
import { NavigationRule } from "../types";
import { RouteIds } from "./routes";
import { authenticationAPI } from "../../authentication/api";
import { balanceAPI } from "../../premium/apis/balanceAPI";
import { splashScreenAPI } from "../../splash";

export const redirectToSplashIfNotSeen = (path: string): NavigationRule => ({
  priority: 100, // Highest priority
  condition: async (session: Session | null): Promise<boolean> => {
    if (!splashScreenAPI.hasSeenSplash()) {
      splashScreenAPI.setIntendedPath(path);
      return true;
    }
    return false;
  },
  redirect: RouteIds.splash,
});

export const redirectToLoginIfNoSession = (priority = 90): NavigationRule => ({
  priority,
  condition: async (session: Session | null): Promise<boolean> =>
    !authenticationAPI.isAuthenticated(session),
  redirect: RouteIds.login,
});

export const redirectIfAnonymousUser = (priority = 1): NavigationRule => ({
  priority,
  condition: async (session: Session | null): Promise<boolean> =>
    authenticationAPI.isAnonymousUser(session),
  redirect: RouteIds.stories,
});

export const redirectIfNoCredits = (priority = 1): NavigationRule => ({
  priority,
  condition: async (session: Session | null): Promise<boolean> => {
    if (!session) return true;
    try {
      const credits = await balanceAPI.getRamaining(session);
      return credits <= 0;
    } catch {
      return true;
    }
  },
  redirect: RouteIds.package,
});
