import { Session } from "@supabase/supabase-js";
import {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { authenticationAPI } from "../api/authenticationAPI";

interface AuthContextType {
  session: Session | null;
  isAnonymous: boolean;
  isAuthenticated: boolean;
  isInitialized: boolean;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const [session, setSession] = useState<Session | null>(
    authenticationAPI.getCurrentSession(),
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const [, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = authenticationAPI.subscribe(
      ({ session, isInitialized }) => {
        setSession(session);
        setIsInitialized(isInitialized);
        setError(null);
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);

  const signOut = useCallback(async () => {
    try {
      await authenticationAPI.signOut();
    } catch (error) {
      setError(error instanceof Error ? error.message : "Unknown error");
    }
  }, []);

  const value = useMemo(
    () => ({
      session,
      isInitialized,
      isAnonymous: authenticationAPI.isAnonymousUser(session),
      isAuthenticated: authenticationAPI.isAuthenticated(session),
      signOut,
    }),
    [session, isInitialized, signOut],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
