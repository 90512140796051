import logo from "@assets/logo.png";
import styles from "./Signup.module.css";
// import {ReactComponent as Logo} from '@assets/logo.svg'
import { Authentication } from "../../features/authentication";

export function Signup() {
  return (
    <div className={styles.root}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="random" />
        {/* <Logo /> */}
      </div>
      <Authentication />
    </div>
  );
}
