import React, { useEffect } from "react";
import { useNavigationGuard } from "../hooks/useNavigationGuard";
import { RouteDefinition } from "../types";
import { useAuth } from "../../authentication";

interface ProtectedRouteProps {
  route: RouteDefinition;
  children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  route,
  children,
}) => {
  const { session, isInitialized } = useAuth();
  const navigationGuard = useNavigationGuard();

  useEffect(() => {
    // Don't check rules while session is is
    if (!isInitialized) {
      return;
    }

    const checkRules = async () => {
      // Check rules in priority order
      const sortedRules = [...route.rules].sort(
        (a, b) => b.priority - a.priority,
      );

      const matchingRule = await sortedRules.reduce<
        Promise<(typeof sortedRules)[0] | null>
      >(async (acc, rule) => {
        const found = await acc;
        if (found) return found;

        const shouldRedirect = await rule.condition(session);
        return shouldRedirect ? rule : null;
      }, Promise.resolve(null));

      if (matchingRule) {
        await navigationGuard.to(matchingRule.redirect);
      }
    };

    checkRules();
  }, [route.rules, session, navigationGuard, isInitialized]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
