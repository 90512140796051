import { Session } from "@supabase/supabase-js";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { StoryDetails } from "../../../App/types";
import { useAuth } from "../../authentication";
import { storyAPI } from "../api/storyAPI";

const REFETCH_INTERVAL_IN_PROGRESS = 2000;
const REFETCH_INTERVAL_COMPLETED = 60000;

const fetchStories = async (session: Session | null) => {
  if (!session) {
    return [];
  }

  return storyAPI
    .getAll(session)
    .then((storyDetails) =>
      storyDetails.filter((story) => story.status !== "failed"),
    );
};

export const useStories = () => {
  const { session, isInitialized } = useAuth();
  const [refetchInterval, setRefetchInterval] = useState(
    REFETCH_INTERVAL_COMPLETED,
  );

  const { data, isLoading, error, refetch } = useQuery(
    "storiesData",
    () => fetchStories(session),
    { refetchInterval, enabled: Boolean(session && isInitialized) },
  );

  const hasInProgressStories = data?.some(
    (story) => story.status !== "completed",
  );

  if (
    hasInProgressStories &&
    refetchInterval !== REFETCH_INTERVAL_IN_PROGRESS
  ) {
    setRefetchInterval(REFETCH_INTERVAL_IN_PROGRESS);
  } else if (
    !hasInProgressStories &&
    refetchInterval === REFETCH_INTERVAL_IN_PROGRESS
  ) {
    setRefetchInterval(REFETCH_INTERVAL_COMPLETED);
  }

  return [data, isLoading, error, refetch] as const;
};

export const useStory = (storyId?: string) => {
  const { session, isInitialized } = useAuth();
  const [story, setStory] = useState<StoryDetails | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchStory = async () => {
      if (!session || !isInitialized || !storyId || isFetching) return;

      setIsFetching(true);
      try {
        const storyDetails = await storyAPI.get(session, storyId);
        setStory(storyDetails);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchStory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, storyId, isInitialized]);

  return story;
};
