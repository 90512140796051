import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { modalAPI } from "features/modal/api/modalAPI";
import { assetRegistry } from "features/assets";
import styles from "./GlobalModal.module.css";

import { modalConfiguration } from "./modalsConfiguration";
import {
  GlobalModalContextType,
  GlobalModalProps,
  ModalType,
} from "../../types";
import { ModalProvider } from "./ModalProvider";

const initalState: GlobalModalContextType = {
  showModal: () => {},
  hideModal: () => {},
  type: null,
};

const GlobalModalContext = createContext(initalState);

export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: React.FC<GlobalModalProps> = ({
  children,
  onNavigate,
}) => {
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  // Register modal assets
  useEffect(() => {
    assetRegistry.register({
      path: "assets/modal-background.svg",
      type: "image",
      format: "image/svg+xml",
    });
  }, []);

  const showModal = useCallback(
    (_modalType: ModalType) => {
      setModalType(_modalType);
      setTimeout(() => {
        setIsOpen(true);
      }, 300);
    },
    [setModalType],
  );

  const hideModal = useCallback(() => {
    if (isOpen) setIsOpen(false);
    setModalType(null);
  }, [setModalType, isOpen]);

  const onDone = useCallback(() => {
    const navigateTo = modalType && modalConfiguration[modalType]?.navigateTo;
    setIsOpen(false);
    setTimeout(() => {
      hideModal();
      if (navigateTo) onNavigate(navigateTo);
    }, 400);
  }, [modalType, hideModal, onNavigate]);

  // Handle auto-navigation
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (modalType && isOpen) {
      const delay = modalConfiguration[modalType]?.autoNavigateAfter;
      if (delay) {
        timer = setTimeout(() => {
          onDone();
        }, delay);
      }
    }

    // Cleanup function that runs on unmount or when dependencies change
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [modalType, isOpen, onDone]);

  // Initialize modal API
  useEffect(() => {
    modalAPI.initialize(showModal, hideModal);
  }, [showModal, hideModal]);

  const contextValue = useMemo(
    () => ({ type: modalType, showModal, hideModal }),
    [modalType, showModal, hideModal],
  );

  return (
    <GlobalModalContext.Provider value={contextValue}>
      {modalType && (
        <ModalProvider
          className={styles.modal}
          modalType={modalType}
          isOpen={isOpen}
          onDone={onDone}
        />
      )}
      {children}
    </GlobalModalContext.Provider>
  );
};
