import React from "react";
import cn from "classnames";

import styles from "./MenuList.module.css";
import { MenuItem } from "./components/MenuItem";

interface Item {
  id: string;
  title?: string;
  icon?: JSX.Element;
  showArrow?: boolean;
  onClick?: () => void;
}

interface MenuListProps {
  items: Item[];
  className?: string;
}

export const MenuList: React.FC<MenuListProps> = ({ className, items }) => (
  <div className={cn(styles.root, className)}>
    {items.map((item, index) => (
      <MenuItem
        className={cn({
          [styles.rotateRight]: index % 2 !== 0,
          [styles.rotateLeft]: index % 2 === 0,
        })}
        index={index}
        {...item}
        color={((index % 4) + 1) as 1 | 2 | 3 | 4}
        key={item.id}
      />
    ))}
  </div>
);
