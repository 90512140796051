import React, { useCallback } from "react";
import cn from "classnames";
import { useNavigate, useLocation } from "react-router-dom";

import { ReactComponent as HomeSelected } from "@assets/home-selected.svg";
import { ReactComponent as Home } from "@assets/home.svg";
// import {ReactComponent as Character} from '@assets/character.svg'
// import {ReactComponent as CharacterSelected} from '@assets/character-selected.svg'
import { ReactComponent as Add } from "@assets/plus-circle.svg";
import styles from "./FooterMenu.module.css";

type FooterMenuProps = {
  className?: string;
  fixedBottom?: boolean;
};

export const FooterMenu: React.FC<FooterMenuProps> = ({
  className,
  fixedBottom,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isStories = useCallback(() => location.pathname === "/", [location]);
  // const isCharacter = useCallback(() => location.pathname === '/characters', [location]);

  const handleAddClick = useCallback(() => {
    if (isStories()) {
      navigate("create");
    } else {
      navigate("create");
    }
  }, [navigate, isStories]);

  return (
    <div
      className={cn(
        styles.root,
        { [styles.fixedBottom]: fixedBottom },
        className,
      )}
    >
      {isStories() ? (
        <HomeSelected onClick={() => navigate("/")} />
      ) : (
        <Home onClick={() => navigate("/")} />
      )}
      {/* {isCharacter() ? <CharacterSelected onClick={() => navigate('characters')}/> :
      <Character onClick={() => navigate('characters')}/>} */}
      <Add onClick={handleAddClick} />
    </div>
  );
};
