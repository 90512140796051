import React from "react";
import cn from "classnames";

import { ReactComponent as Arrow } from "@assets/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import styles from "./Back.module.css";

type BackProps = {
  className?: string;
  onClick?: () => void;
};
export const Back: React.FC<BackProps> = ({ className, onClick }) => {
  const navigate = useNavigate();

  return (
    <div
      className={cn(styles.root, className)}
      onClick={
        onClick ||
        (() => {
          navigate(-1);
        })
      }
    >
      <Arrow />
      <div className={styles.text}>Back</div>
    </div>
  );
};
