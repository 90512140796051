import React, { useState } from "react";
import { ReactComponent as Crown } from "@assets/crown.svg";
import { useNavigate } from "react-router-dom";
import { useBalance } from "features/premium/hooks/balance";
import { useProducts } from "features/premium/hooks/products";
import styles from "./PackagePicker.module.css";
import { useAuth } from "../../features/authentication";
import { Button } from "../../components/shared/Button";

import { PackageList } from "../../features/premium/components/PackageList";
import { Header } from "../../components";

export const PackagePicker = () => {
  const { session } = useAuth();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string | null>(null);
  const products = useProducts();
  const balance = useBalance();

  if (!session) {
    return <div>Not logged in</div>;
  }

  return (
    <div className={styles.root}>
      <Header fixedPosition withBackButton withBackground={false} />
      <div className={styles.thumbnailContainer}>
        <Crown />
      </div>
      <div className={styles.content}>
        <div className={styles.credits}>{`${balance} Credits Left`}</div>
        <div className={styles.paragraph}>
          Every purchased credit will entitle you to a magical story experience
          just for you.
        </div>
      </div>
      <div className={styles.buttons}>
        <PackageList
          className={styles.list}
          items={
            products?.map(({ id, description }) => ({
              id,
              title: description,
            })) || []
          }
          onItemClick={setSelected}
          selected={selected || ""}
        />
      </div>
      <div className={styles.payButtonContainer}>
        <Button
          fullWidth
          disabled={!selected}
          text="Payment"
          onClick={() => {
            navigate(`/payment/${selected}`);
          }}
        />
      </div>
    </div>
  );
};
