import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import { App } from "./App";
import { SessionProvider } from "./features/authentication/context/AuthContext";
import { authenticationAPI } from "./features/authentication/api/authenticationAPI";
import { exposeAPIs } from "./utils/exposeAPIs";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://ffe46dbc358136367e64b3224f2ed5ac@o4507317830680576.ingest.us.sentry.io/4507317833760768",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want
    // to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change
    // the sample rate to  100% when sampling sessions where errors occur.
  });
}

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// Initialize authentication before rendering
authenticationAPI.initialize().then(() => {
  root.render(
    <React.StrictMode>
      <SessionProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </SessionProvider>
    </React.StrictMode>,
  );

  // Expose APIs to the console
  if (process.env.NODE_ENV === "development") {
    exposeAPIs();
  }
});
