import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Page.module.css";
import { Slider } from "../../../../features/story/components/Slider";

export type PageProps = {
  title?: string;
  text?: string;
  imageUrl?: string;
  color?: 2 | 3;
  pageIndex: number;
  pageAmount: number;
  className?: string;
};

export const Page: React.FC<PageProps> = ({
  title,
  text,
  imageUrl,
  className,
  color,
  pageAmount,
  pageIndex,
}) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    const container = rootRef.current;
    const slider = sliderRef.current;
    if (container) {
      const hasOverflowX = container.scrollWidth > container.clientWidth;
      const hasOverflowY =
        container.scrollHeight >
        container.clientHeight - (slider?.clientHeight || 0);
      setHasOverflow(hasOverflowX || hasOverflowY);
    }
  }, []);

  return (
    <div
      ref={rootRef}
      className={cn(
        styles.root,
        styles[`color-${color || 2}`],
        { [styles.overflow]: hasOverflow },
        className,
      )}
    >
      {imageUrl && (
        <div className={styles.containerWrapper}>
          <div className={styles.imageContainer}>
            <img className={styles.image} alt="page" src={imageUrl} />
          </div>
          <div
            className={cn(
              styles.imageContainerBackground,
              styles[`imageContainerBackgroundColor${color || 2}`],
            )}
          />
          <div className={styles.imageContainerBackgroundBlur} />
        </div>
      )}
      <div className={styles.textWrapper}>
        {title && <div className={styles.title}>{title}</div>}
        <div className={cn(styles.text, { [styles.padding]: hasOverflow })}>
          {text}
        </div>
      </div>
      <div className={styles.sliderContainer} ref={sliderRef}>
        <Slider color={color} amount={pageAmount} current={pageIndex} />
      </div>
    </div>
  );
};
