export type StoryBase = {
  id: string;
  created_at: string;
  owner: string;
  status: Status;
};

export type Status = "pending" | "processing" | "completed" | "failed";

const isStatus = (status: string): status is Status =>
  ["pending", "processing", "completed", "failed"].includes(status);

export type Story = StoryBase & {
  name?: string;
  thumbnail?: string;
  prompt?: string;
  sections?: Section[];
  characters?: Character[];
};

export type Character = {
  name: string;
  description: string;
};

export type Section = {
  text: string;
  imageDescription: string;
  imageUrl?: string;
  speechUrl?: string;
};

export const isStoryBase = (story: object): story is StoryBase =>
  typeof (story as Story).id === "string" &&
  typeof (story as Story).owner === "string" &&
  isStatus((story as Story).status);

export const isStory = (story: object): story is Story =>
  typeof (story as Story).id === "string" &&
  typeof (story as Story).owner === "string" &&
  (typeof (story as Story).prompt === "undefined" ||
    (story as Story).prompt === null ||
    typeof (story as Story).prompt === "string") &&
  (typeof (story as Story).name === "undefined" ||
    typeof (story as Story).name === "string") &&
  Array.isArray((story as Story).characters) &&
  ((story as Story).characters?.every(isCharacter) ?? true) &&
  Array.isArray((story as Story).sections) &&
  ((story as Story).sections?.every(isSection) ?? true);

export const isCharacter = (character: object): character is Character =>
  typeof (character as Character).name === "string" &&
  typeof (character as Character).description === "string";

export const isSection = (section: object): section is Section =>
  typeof (section as Section).text === "string" &&
  typeof (section as Section).imageDescription === "string";
