import React, {
  ChangeEvent,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import cn from "classnames";

import styles from "./TextArea.module.css";

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  className?: string;
  minHeight?: string;
};

export const TextArea: React.FC<TextAreaProps> = ({
  className,
  children,
  value,
  onChange,
  minHeight = "0px",
  ...rest
}) => {
  const [textareaHeight, setTextareaHeight] = useState<string>("auto");
  const textArea = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textArea.current) {
      updateTextareaHeight(textArea.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    if (onChange) {
      onChange(event);
    }
    updateTextareaHeight(event.target);
  };

  const updateTextareaHeight = (element: HTMLTextAreaElement): void => {
    element.style.height = "auto";
    const newHeight = Math.max(element.scrollHeight, parseInt(minHeight, 10));
    element.style.height = `${newHeight}px`;
    setTextareaHeight(element.style.height);
  };

  return (
    <div className={cn(styles.root, className)}>
      <textarea
        className={styles.textArea}
        {...rest}
        ref={textArea}
        onChange={handleChange}
        style={{ height: textareaHeight }}
        value={value}
      />
    </div>
  );
};
